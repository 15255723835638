<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Vraag een account aan" />
    <h1>Vraag een account aan</h1>
    <p>
      Via dit formulier kan je als vereniging een account aanvragen voor het
      e-loket van de provinciale uitleendienst Vlaams-Brabant. Wij controleren
      steeds of je in aanmerking komt hiervoor, dus lees zeker ons
      <a href="https://www.vlaamsbrabant.be/nl/uitleendienst-reglement"
        >Reglement</a
      >
      alvorens je een account aanvraagt. Ben je geen erkende vereniging maar
      staat je lokaal bestuur achter je initiatief, dan kan je
      <a href="https://www.vlaamsbrabant.be/nl/feitelijke-vereniging">dit</a>
      formulier gebruiken.
    </p>
    <p v-if="!loggedIn">
      Als je als gebruiker al bekend bent in ons systeem dien je eerst in te
      loggen en op je accountpagina een vereniging toe te voegen.
    </p>
    <h2>Vul het formulier zo volledig mogelijk in</h2>
    <b-form @submit.prevent="registerUser">
      <b-row>
        <b-col md="10">
          <b-card>
            <h4>Deel 1 - Gegevens over vereniging, dienst of instelling</h4>

            <b-row>
              <b-col cols="12" class="pb-3">
                <label>
                  Naam vereniging
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.companyName"
                  maxlength="50"
                  name="companyName"
                  required
                ></b-form-input>
              </b-col>
              <b-col xs="8" md="9" class="pb-3">
                <label>
                  Straatnaam
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.street"
                  maxlength="50"
                  name="streetName"
                  required
                ></b-form-input>
              </b-col>
              <b-col xs="4" md="3">
                <label>
                  Huisnr.
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.houseNumber"
                  maxlength="10"
                  required
                  name="houseNumber"
                ></b-form-input>
              </b-col>
              <b-col xs="4" class="pb-3">
                <label>
                  Postcode
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.postcode"
                  :max="9999"
                  maxlength="4"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); javascript: if (isNaN(this.value)) this.value = this.value.substring(0, this.value.length - 1);"
                  required
                  name="postalCode"
                  type="text"
                ></b-form-input>
              </b-col>
              <b-col cols="8">
                <label>
                  Gemeente
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.city"
                  name="city"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="6" class="pb-3">
                <label>
                  E-mailadres
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.email"
                  maxlength="255"
                  name="email"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="6">
                <label>
                  Telefoonnummer
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.tel"
                  name="tel"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="6" class="pb-3">
                <label>BTW- of ondernemingsnummer (optioneel)</label>

                <b-form-input
                  v-model="form.vatid"
                  maxlength="10"
                  :state="vatIDValid"
                  name="vatid"
                  oninput="javascript: if (isNaN(this.value)) this.value = this.value.substring(0, this.value.length - 1);"
                ></b-form-input>
                <b-form-text id="vat-help-block">
                  <p>
                    Gelieve enkel de 10 cijfers van het btw-of
                    ondernemingsnummer in te voeren zonder leestekens
                  </p>
                </b-form-text>
              </b-col>

              <b-col sm="12" md="6">
                <label>
                  Website of facebook pagina
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.website"
                  maxlength="50"
                  name="website"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label>
                    Je vereniging is erkend/gesubsidieerd (meerdere opties
                    mogelijk)
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-checkbox-group
                    id="targetAudience"
                    v-model="form.targetAudience"
                    name="acknowledgedOrganization"
                    stacked
                    :options="targetAudienceOptions"
                  ></b-form-checkbox-group>
                </b-form-group>
                <b-form-group>
                  <label>
                    De doelgroep van je vereniging (meerdere opties mogelijk)
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-checkbox-group
                    id="targetAudienceExtra"
                    v-model="form.targetAudienceExtra"
                    name="acknowledgedOrganization2"
                    stacked
                    :options="targetAudienceExtraOptions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="mt-4">
            <h4>Deel 2 - Gegevens van de aanvrager</h4>
            <b-row>
              <b-col xs="12" md="6" class="pb-3">
                <label>
                  Voornaam
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.firstname"
                  maxlength="50"
                  name="firstname"
                  required
                  :disabled="loggedIn"
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="6" class="pb-3">
                <label>
                  Achternaam
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.lastname"
                  maxlength="50"
                  required
                  name="lastname"
                  :disabled="loggedIn"
                ></b-form-input>
              </b-col>
              <b-col xs="8" md="9" class="pb-3">
                <label>
                  Straatnaam
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.contactStreet"
                  maxlength="50"
                  required
                  name="contactStreet"
                  :disabled="loggedIn"
                ></b-form-input>
              </b-col>
              <b-col xs="4" md="3">
                <label>
                  Huisnr.
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.contactHouseNumber"
                  maxlength="8"
                  required
                  name="contactHouseNumber"
                  :disabled="loggedIn"
                ></b-form-input>
              </b-col>
              <b-col cols="4" class="pb-3">
                <label>
                  Postcode
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.contactPostcode"
                  type="text"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); javascript: if (isNaN(this.value)) this.value = this.value.substring(0, this.value.length - 1);"
                  max="9999"
                  maxlength="4"
                  required
                  name="contactPostcode"
                  :disabled="loggedIn"
                ></b-form-input>
              </b-col>
              <b-col cols="8">
                <label>
                  Gemeente
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.contactCity"
                  maxlength="50"
                  name="contactCity"
                  required
                  :disabled="loggedIn"
                ></b-form-input>
              </b-col>
              <b-col s="12" md="6" class="pb-3">
                <label>
                  E-mailadres
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.contactEmail"
                  maxlength="255"
                  required
                  name="contactEmail"
                  :disabled="loggedIn"
                ></b-form-input>
              </b-col>
              <b-col cs="12" md="6">
                <label>
                  Telefoonnummer
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.contactTel"
                  maxlength="50"
                  required
                  name="contactTel"
                  :disabled="loggedIn"
                ></b-form-input>
              </b-col>
              <b-col v-if="!loggedIn" cols="12">
                <hr />
                <b-form-text id="password-help-block">
                  <p>Een nieuwe wachtwoord moet:</p>
                  <ul>
                    <li>Minimaal 8 karakters lang zijn</li>
                    <li>Minimaal één hoofdletter bevatten (A t/m Z)</li>
                    <li>Minimaal één kleine letter bevatten (a t/m z)</li>
                    <li>Minimaal één cijfer bevatten (0 t/m 9)</li>
                    <li>Minimaal één speciale teken bevatten (!@#$%&)</li>
                  </ul>
                </b-form-text>
              </b-col>
              <b-col v-if="!loggedIn" cols="6" class="pb-3">
                <label>
                  Wachtwoord
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.password"
                  :required="!loggedIn"
                  :state="passwordValidation"
                  type="password"
                  name="password"
                  min="8"
                ></b-form-input>
              </b-col>
              <b-col v-if="!loggedIn" cols="6">
                <label>
                  Herhaal wachtwoord
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.confirmPassword"
                  type="password"
                  :state="passwordConfirmState && passwordValidation"
                  :required="!loggedIn"
                  name="confirmPassword"
                  min="8"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="mt-4">
            <h4>Deel 3 - Facturatiegegevens</h4>
            <b-form-checkbox v-model="invoiceSameAsOrganisationAddress"
              >Factuurgegevens zijn dezelfde als de
              organisatiegegevens</b-form-checkbox
            >
            <div v-if="!invoiceSameAsOrganisationAddress">
              <b-row>
                <b-col s="12" md="6" class="pb-3">
                  <label>
                    Naam op factuur
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    v-model="form.invoiceCompanyName"
                    :disabled="invoiceSameAsOrganisationAddress"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-col>
                <b-col xs="12" md="6" class="pb-0">
                  <label>BTW- of ondernemingsnummer (optioneel)</label>
                  <b-form-input
                    v-model="form.invoiceVatid"
                    maxlength="10"
                    :state="invoiceVatIDValid"
                    name="invoiceVatid"
                    oninput="javascript: if (isNaN(this.value)) this.value = this.value.substring(0, this.value.length - 1);"
                    :disabled="invoiceSameAsOrganisationAddress"
                  ></b-form-input>
                  <b-form-text id="vat-help-block">
                    <p>
                      Gelieve enkel de 10 cijfers van het btw-of
                      ondernemingsnummer in te voeren zonder leestekens
                    </p>
                  </b-form-text>
                </b-col>

                <b-col xs="12" md="9" class="pb-3">
                  <label>
                    Straatnaam
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    v-model="form.invoiceStreet"
                    maxlength="50"
                    :disabled="invoiceSameAsOrganisationAddress"
                    required
                  ></b-form-input>
                </b-col>
                <b-col xs="4" md="3">
                  <label>
                    Huisnr.
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    v-model="form.invoiceHouseNumber"
                    maxlength="8"
                    oninput="javascript: if (isNaN(this.value)) this.value = this.value.substring(0, this.value.length - 1);"
                    :disabled="invoiceSameAsOrganisationAddress"
                    required
                  ></b-form-input>
                </b-col>
                <b-col xs="8" md="4" class="pb-3">
                  <label>
                    Postcode
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    v-model="form.invoicePostcode"
                    type="number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    max="9999"
                    maxlength="4"
                    :disabled="invoiceSameAsOrganisationAddress"
                    required
                  ></b-form-input>
                </b-col>
                <b-col xs="12" md="8">
                  <label>
                    Gemeente
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    v-model="form.invoiceCity"
                    :disabled="invoiceSameAsOrganisationAddress"
                    required
                  ></b-form-input>
                </b-col>
                <b-col xs="12" md="6" class="pb-3">
                  <label>
                    E-mailadres
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    v-model="form.invoiceEmail"
                    :disabled="invoiceSameAsOrganisationAddress"
                    required
                  ></b-form-input>
                </b-col>
                <b-col xs="12" md="6">
                  <label>
                    Telefoonnummer
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    v-model="form.invoiceTel"
                    :disabled="invoiceSameAsOrganisationAddress"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
            </div>
          </b-card>
          <b-card class="mt-4">
            <h4>Deel 4 - Opmerkingen</h4>

            <b-row>
              <b-col cols="12" class="pb-3">
                <label>Heb je een opmerking of vraag? Schrijf ze hier!</label>
                <b-form-textarea
                  id="textarea"
                  v-model="form.contactOpmerking"
                  placeholder
                  rows="3"
                  max-rows="6"
                  maxlength="255"
                  oninput="javascript: if (this.value.includes('<')) this.value = this.value.slice(0, this.maxLength);"
                ></b-form-textarea>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="form.newsletter"
                    name="newsletter"
                    stacked
                    :options="this.form.newsletterOption"
                  >
                    <b-form-checkbox
                      >Inschrijven voor de nieuwsbrief</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col cols="8" class="pb-3">
                <label>Extra documenten (indien noodzakelijk)</label>
                <br />
                <b-button variant="outline-primary" @click="chooseFiles()"
                  >Selecteer bestanden...</b-button
                >
                <b-form-file
                  id="chooseFiles"
                  v-model="form.file"
                  class="mt-3"
                  plain
                  hidden
                ></b-form-file>
              </b-col>
              <b-col md="4">
                <div class="mt-5">
                  <i class="mt-3">
                    {{
                      form.file
                        ? form.file.name
                        : 'Geen bestanden geselecteerd...'
                    }}
                  </i>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col v-if="error" cols="12" class="mt-5">
              <b-alert show variant="warning">
                <font-awesome-icon icon="exclamation-circle" />
                <span class="alert-message" v-html="errorMessage" />
              </b-alert>
            </b-col>
          </b-row>
          <b-card class="mt-4">
            <b-row>
              <b-col>
                <h2 class="pt-2">Bevestigen?</h2>
              </b-col>
              <b-col xs="12" md="3">
                <b-button variant="primary" type="submit">
                  Aanvraag bevestigen
                  <font-awesome-icon
                    v-if="registering"
                    class="fa-spin"
                    far
                    icon="spinner"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>
<script>
const {
  registerWithFiles,
  newOrganizationWithFiles
} = require('@/services/AuthorizationService')
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      form: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        street: '',
        houseNumber: '',
        postcode: '',
        city: '',
        tel: '',
        companyName: '',
        vatid: '',
        website: '',
        targetAudience: [],
        targetAudienceExtra: [],
        firstname: '',
        lastname: '',
        contactEmail: '',
        contactStreet: '',
        contactHouseNumber: '',
        contactPostcode: '',
        contactCity: '',
        contactTel: '',
        contactOpmerking: '',
        invoicename: '',
        vat: '',
        newsletter: [],
        invoiceCompanyName: '',
        invoiceStreet: '',
        invoiceHouseNumber: '',
        invoicePostcode: '',
        invoiceCity: '',
        invoiceEmail: '',
        invoiceTel: '',
        invoiceVatid: '',
        file: null,
        passwordValidation: null
      },
      invoiceSameAsOrganisationAddress: true,
      targetAudienceOptions: [
        { text: 'door de gemeente', value: 'Gemeente' },
        {
          text: 'door de provincie Vlaams-Brabant',
          value: 'Provincie Vlaams-Brabant'
        },
        { text: 'door de Vlaamse Gemeenschap', value: 'Vlaamse Gemeenschap' },
        { text: 'is een provinciale dienst', value: 'Provinciale dienst' },
        { text: 'is een school', value: 'School' }
      ],
      targetAudienceExtraOptions: [
        { text: 'Jeugd', value: 'Jeugd' },
        { text: 'Cultuur', value: 'Cultuur' },
        { text: 'Onderwijs', value: 'Onderwijs' },
        { text: 'Sport', value: 'Sport' },
        { text: 'Milieu', value: 'Milieu' },
        { text: 'Welzijn & Gezondheid', value: 'Welzijn & Gezondheid' },
        { text: 'Musea / Erfgoed', value: 'Musea / Erfgoed' },
        { text: 'Religieus', value: 'Religieus' },
        { text: 'Gemeentelijke dienst', value: 'Gemeentelijke dienst' },
        { text: 'Hulpdienst', value: 'Hulpdienst' },
        { text: 'Politiek', value: 'Politiek' }
      ],
      newsletterOption: [
        { text: 'Inschrijven voor de nieuwsbrief', value: 'true' }
      ],
      registering: false,
      error: false,
      errorMessage: null
    }
  },
  metaInfo: function() {
    return {
      title: `Vraag een account aan`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst'
    }
  },

  computed: {
    loggedIn: function() {
      if (
        this.$store.state.account !== null &&
        Object.keys(this.$store.state.account).length !== 0
      )
        return true

      return false
    },
    passwordConfirmState() {
      if (this.form.password != '') {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    },
    passwordContainsInvalidSpecials() {
      const password = this.form.password

      if (password)
        return !/^([0-9a-zA-Z]*)([!@#$%&]*)$/.exec(this.form.password)

      return false
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '') {
        if (password.length < 8) {
          return false
        }

        if (!password.match(/[!@#$%&]/)) return false
        if (!password.match(/[0-9]/)) return false
        if (!password.match(/[a-z]/)) return false
        if (!password.match(/[A-Z]/)) return false
        if (!/^([0-9a-zA-Z!@#$%&]*)$/.exec(password)) return false

        return true
      }

      return null
    },
    vatIDValid() {
      const vatID = this.form.vatid
      if (vatID.length < 1) return null
      return /(BE)?0[0-9]{9}/.test(vatID)
    },
    invoiceVatIDValid() {
      const vatID = this.form.invoiceVatid
      if (vatID.length < 1) return null
      return /(BE)?0[0-9]{9}/.test(vatID)
    },
    newsletter() {
      if (this.form.newsletter[0]) {
        return true
      }
      return false
    }
  },
  watch: {
    'form.contactOpmerking': function(val) {
      this.form.contactOpmerking = val.replace(/[<>]/g, '')
    }
  },
  created() {
    if (this.loggedIn) {
      this.form.firstname = this.$store.state.account.FirstName
      this.form.lastname = this.$store.state.account.LastName
      this.form.contactEmail = this.$store.state.account.Email
      this.form.contactStreet = this.$store.state.account.CUST_Street
      this.form.contactHouseNumber = this.$store.state.account.CUST_HouseNumber
      this.form.contactPostcode = this.$store.state.account.CUST_ZipCode
      this.form.contactCity = this.$store.state.account.CUST_City
      this.form.contactTel = this.$store.state.account.Tel
    }
  },
  methods: {
    async registerUser(event) {
      this.error = false
      this.registering = true

      event.preventDefault()

      let formData = new FormData()

      let invoiceCompanyName = this.form.invoiceCompanyName
      let invoiceStreet = this.form.invoiceStreet
      let invoiceHouseNumber = this.form.invoiceHouseNumber
      let invoicePostcode = this.form.invoicePostcode
      let invoiceCity = this.form.invoiceCity
      let invoiceEmail = this.form.invoiceEmail
      let invoiceTel = this.form.invoiceTel
      let vatid = this.form.invoiceVatid

      if (this.invoiceSameAsOrganisationAddress) {
        invoiceCompanyName = this.form.companyName
        invoiceStreet = this.form.street
        invoiceHouseNumber = this.form.houseNumber
        invoicePostcode = this.form.postcode
        invoiceCity = this.form.city
        invoiceEmail = this.form.email
        invoiceTel = this.form.tel
        vatid = this.form.vatid
      }

      formData.append('UserName', this.form.contactEmail)
      formData.append('Email', this.form.email)
      formData.append('UserEmail', this.form.contactEmail)
      formData.append('Password', this.form.password)
      formData.append('ConfirmPassword', this.form.confirmPassword)
      formData.append('Street', this.form.street)
      formData.append('HouseNumber', this.form.houseNumber)
      formData.append('ZipCode', this.form.postcode)
      formData.append('City', this.form.city)
      formData.append('Tel', this.form.tel)
      formData.append('CompanyName', this.form.companyName)
      formData.append('VATNo', vatid)
      formData.append('Website', this.form.website)
      formData.append(
        'CUST_TargetAudience',
        `${this.form.targetAudience.join()} - ${this.form.targetAudienceExtra.join()}`
      )
      formData.append('FirstName', this.form.firstname)
      formData.append('LastName', this.form.lastname)
      formData.append('CONTACT_Email', this.form.contactEmail)
      formData.append('CUST_Street', this.form.contactStreet)
      formData.append('CUST_HouseNumber', this.form.contactHouseNumber)
      formData.append('CUST_ZipCode', this.form.contactPostcode)
      formData.append('CUST_City', this.form.contactCity)
      formData.append('CONTACT_MobilePhone', this.form.contactTel)
      formData.append('CUST_Recognition', this.form.contactOpmerking)
      formData.append('CUST_ReceiveNewsletter', this.newsletter)
      formData.append('InvoiceCompanyName', invoiceCompanyName)
      formData.append('InvoiceStreet', invoiceStreet)
      formData.append('InvoiceHouseNumber', invoiceHouseNumber)
      formData.append('InvoiceZipCode', invoicePostcode)
      formData.append('InvoiceCity', invoiceCity)
      formData.append('InvoiceEmail', invoiceEmail)
      formData.append(
        'ConfirmURL',
        `${process.env.VUE_APP_FRONTEND_URL}/account-aangemaakt/`
      )

      formData.append('InvoiceTel', invoiceTel)
      if (this.form.file) {
        formData.append(
          `${this.form.companyName}-${this.form.file.name}`,
          this.form.file
        )
      }

      //Validators
      if (this.form.password != this.form.confirmPassword && !this.loggedIn) {
        this.registering = false
        return
      }

      if (!this.passwordValidation && !this.loggedIn) {
        this.displayErrorMessage(
          'Het ingevulde wachtwoord voldoet niet aan de vermelde vereisten'
        )
        return
      }

      if (this.form.password.contains && !this.loggedIn) {
        this.displayErrorMessage(
          'Het ingevulde wachtwoord voldoet niet aan de vermelde vereisten'
        )
        return
      }

      if (this.invoiceVatIDValid === false || this.vatIDValid === false) {
        this.displayErrorMessage(
          'Het ingevulde BTW- of ondernemingsnummer voldoet niet aan de vermelde vereisten'
        )
        return
      }

      if (
        this.form.targetAudience.length < 1 ||
        this.form.targetAudienceExtra < 1
      ) {
        this.displayErrorMessage(
          'Selecteer op zijn minst 1 optie voor de erkenning/gesubsidieerde status en doelgroep van je vereniging.'
        )
        return
      }

      let result = {}

      if (this.loggedIn) {
        result = await newOrganizationWithFiles({ formData })
      } else {
        result = await registerWithFiles({
          formData
        })
      }

      if (
        result.data.Message === 'The entered e-mail address is unauthorized.'
      ) {
        this.displayErrorMessage(
          'Het ingevulde e-mailadres van de gebruiker is reeds gebruikt voor registratie en kan in dit formulier niet worden hergebruikt.<br/> Je kan een nieuwe vereniging aanmelden via je persoonlijke dashboard, daar kan je het e-mailadres wel hergebruiken.<br/> Je kan een nieuwe gebruiker van een bestaande vereniging aanmelden via het dashboard van de hoofdgebruiker van die vereniging.'
        )

        return
      }

      if (result.data.Message != 'InvalidModelState' && result.data === 'OK') {
        if (this.loggedIn) {
          this.$router.push({ name: 'accountCreatedExistingAccount' })
        } else {
          this.$router.push({ name: 'accountEmailSend' })
        }
      } else {
        this.displayErrorMessage(
          'Er is iets mis gegaan bij het aanvragen van je account, probeer het later opnieuw.'
        )
        return
      }
      this.registering = false
    },
    chooseFiles: function() {
      document.getElementById('chooseFiles').click()
    },
    displayErrorMessage: function(errorMessage) {
      this.registering = false
      this.error = true
      this.errorMessage = errorMessage
    }
  }
}
</script>
<style lang="scss" scoped>
.alert-message p > span {
  font-family: 'Roboto' !important;
}
</style>
